import React from "react"
import { useRouter } from "next/router"
import colors from "@constants/colors"
import { Box, Flex } from "@chakra-ui/react"
import Image from "next/image"
import SelectInput from "@components/form/inputs/SelectInput"
import TextInput from "@components/form/inputs/TextInput"
import StyledPortableText from "./StyledPortableText"
import VerticalSpace from "@components/VerticalSpace"
import { bp } from "@utils/MediaQueries"
import { getSearchByCaseSizeUrl } from "@utils/urls"
import _ from "lodash"

const ENTER_KEY = "Enter"

export interface SearchByCaseSizeProps {
  image?: string
  description: any
  onRequestClose: () => void
}

const SearchByCaseSize: React.FC<SearchByCaseSizeProps> = ({
  image,
  description,
  onRequestClose,
}) => {
  const router = useRouter()
  const [units, setUnits] = React.useState<string>("inches")
  const [length, setLength] = React.useState<number | undefined>()
  const [width, setWidth] = React.useState<number | undefined>()
  const [depth, setDepth] = React.useState<number | undefined>()

  const reset = () => {
    setUnits("inches")
    setLength(undefined)
    setWidth(undefined)
    setDepth(undefined)
  }

  const onKeyDown = (event: any) => {
    if (event.key === ENTER_KEY && units && length && width && depth) {
      router.push(getSearchByCaseSizeUrl({ units, length, width, depth }))
      onRequestClose()
      reset()
    }
  }

  return (
    <Box pb="90px">
      <Box
        w="100%"
        mt="8px"
        p={bp(
          null,
          null,
          "0 calc((2 / 16) * 100vw)",
          "0 calc((4 / 16) * 100vw)"
        )}
      >
        <Flex
          direction={bp("column", "column", "row", "row")}
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            width={bp("calc(100% - 40px)", "calc(100% - 40px)", null, null)}
            marginTop={bp("20px", "20px", "10px", "10px")}
            flex={bp(null, null, "1", "1")}
            mr={bp(null, null, "10px", "10px")}
          >
            <SelectInput
              name="Units"
              label="Units"
              value={units}
              options={[
                { label: "inches", value: "inches" },
                { label: "cm", value: "cm" },
              ]}
              onChange={(e) => {
                setUnits(e.target.value)
              }}
            />
          </Flex>
          <Flex
            width={bp("calc(100% - 40px)", "calc(100% - 40px)", null, null)}
            marginTop={"10px"}
            flex={bp(null, null, "1", "1")}
            mr={bp(null, null, "10px", "10px")}
          >
            <TextInput
              type="number"
              name="Length"
              label="Length"
              onChange={(e) => {
                setLength(parseFloat(e.target.value))
              }}
              onKeyDown={onKeyDown}
            />
          </Flex>
          <Flex
            width={bp("calc(100% - 40px)", "calc(100% - 40px)", null, null)}
            marginTop={"10px"}
            flex={bp(null, null, "1", "1")}
            mr={bp(null, null, "10px", "10px")}
          >
            <TextInput
              type="number"
              name="Width"
              label="Width"
              onChange={(e) => {
                setWidth(parseFloat(e.target.value))
              }}
              onKeyDown={onKeyDown}
            />
          </Flex>
          <Flex
            width={bp("calc(100% - 40px)", "calc(100% - 40px)", null, null)}
            marginTop={"10px"}
            flex={bp(null, null, "1", "1")}
            mr={bp(null, null, "10px", "10px")}
          >
            <TextInput
              type="number"
              name="Depth"
              label="Depth"
              onChange={(e) => {
                setDepth(parseFloat(e.target.value))
              }}
              onKeyDown={onKeyDown}
            />
          </Flex>
          <Flex
            width={bp(
              "calc(100% - 40px)",
              "calc(100% - 40px)",
              "127px",
              "127px"
            )}
            marginTop={"10px"}
            as="button"
            alignItems="center"
            justifyContent="center"
            h="58px"
            p={bp(null, null, "0 32px", "0 32px")}
            borderRadius="6px"
            bgColor={colors.dark}
            fontFamily="Avenir"
            fontWeight="800"
            fontSize="16px"
            lineHeight="58px"
            textAlign="center"
            textTransform="uppercase"
            color={colors.white}
            _disabled={{
              bgColor: colors.dark50,
              cursor: "not-allowed",
            }}
            disabled={!units || !length || !width || !depth}
            onClick={() => {
              if (units && length && width && depth) {
                router.push(
                  getSearchByCaseSizeUrl({ units, length, width, depth })
                )
                onRequestClose()
                reset()
              }
            }}
          >
            Search
          </Flex>
        </Flex>
      </Box>
      <VerticalSpace height={62} />
      <Flex
        p={bp(
          null,
          null,
          "0 calc((2 / 16) * 100vw)",
          "0 calc((4 / 16) * 100vw)"
        )}
      >
        {image && (
          <Box
            position="relative"
            flexShrink={0}
            w={bp(
              "initial",
              null,
              "calc((2.50 / 16) * 100vw)",
              "calc((1.50 / 16) * 100vw)"
            )}
            mr={bp(
              "0",
              null,
              "calc((0.5 / 16) * 100vw)",
              "calc((0.25 / 16) * 100vw)"
            )}
          >
            <Image
              src={image}
              alt={"Search by Case Size"}
              layout="fill"
              objectFit="contain"
              objectPosition="top center"
            />
          </Box>
        )}
        <Box
          margin="0 20px 0 20px"
          textAlign={bp("center", "center", "left", "left")}
        >
          <StyledPortableText text={description} />
        </Box>
      </Flex>
    </Box>
  )
}

export default SearchByCaseSize
