import React from "react"
import { throttle } from "lodash"

const useResizeHandler = ({
  onResize,
  onCleanUp,
  runForProps,
}: {
  onResize: () => void
  onCleanUp?: () => void
  runForProps?: Array<any>
}) => {
  const onResizeThrottled = throttle(onResize, 200)

  React.useEffect(
    (): any => {
      window.addEventListener("resize", onResizeThrottled)

      return () => {
        window.removeEventListener("resize", onResizeThrottled)
        onCleanUp && onCleanUp()
      }
    },
    runForProps ? runForProps : []
  )
}

export default useResizeHandler
