import React from "react"

const ZipCodeModal: React.FC<any> = () => {
  const [isMounted, setIsMounted] = React.useState(false)

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  return !isMounted ? null : (
    <div
      suppressHydrationWarning
      className="rsSessionElement rsZipCodeModalContainer"
    />
  )
}

export default ZipCodeModal
