import { groq } from "next-sanity"

export const getSearchResults = groq`{
  "products": *[_type in ["rack", "flyRack", "standardCase", "cylindricalCase", "stringProduct", "wind", "accessory"] && [productName, sku, description[].children[].text, tags, variants[].sku, variants[].description[].children[].text] match $query][0...16] {
    productName,
    "slug": slug.current,
    sku,
    price,
    "categories": categories[]-> {
      slug,
      name,
    },
    "mainImage": mainImage.asset->url,
    variants[] {
      sku,
      price,
      "mainImage": mainImage.asset->url,
    }
  },
  "placeholderImage": *[_type == "siteConfig"].placeholderImage {
  	"url": asset->url
	},
}`
