import React from "react"
import { useRouter } from "next/router"
import colors from "@constants/colors"
import { Box, Flex } from "@chakra-ui/react"
import Image from "next/image"
import SelectInput from "@components/form/inputs/SelectInput"
import StyledPortableText from "./StyledPortableText"
import VerticalSpace from "@components/VerticalSpace"
import { bp } from "@utils/MediaQueries"
import {
  getSearchByRackDimensionsUrl,
  getSearchByRackDepth,
  getSearchByRackUnits,
} from "@utils/urls"
import _ from "lodash"

export interface SearchByRackDimensionsProps {
  rackDimensions: Array<{
    units: number
    depth: number
  }>
  image?: string
  description: any
  onRequestClose: () => void
}

const SearchByRackDimensions: React.FC<SearchByRackDimensionsProps> = ({
  rackDimensions,
  image,
  description,
  onRequestClose,
}) => {
  const router = useRouter()
  const [units, setUnits] = React.useState<string>("inches")
  const [rackUnits, setRackUnits] = React.useState<number | undefined>()
  const [rackDepth, setRackDepth] = React.useState<number | undefined>()
  const [rackDepths, setRackDepths] = React.useState<Array<number>>([])

  const rackUnitOptions = _.uniq(rackDimensions.map(({ units }) => units))
    .filter((v) => !!v)
    .sort((a, b) => (a < b ? -1 : 1))

  const getRackDepths = (rackUnits?: number) => {
    return _.uniq(
      rackDimensions
        .filter(({ units }) => (rackUnits ? units === rackUnits : true))
        .map(({ depth }) => depth)
        .filter((v) => !!v)
    ).sort((a, b) => (a < b ? -1 : 1))
  }

  React.useEffect(() => {
    setRackDepths(getRackDepths())
  }, [rackDimensions])

  const onSelectUnits = (e) => {
    const rackUnitsValue = parseFloat(e.target.value)
    const filteredRackDepths = getRackDepths(rackUnitsValue)

    setRackUnits(rackUnitsValue)
    setRackDepths(filteredRackDepths)
  }

  const reset = () => {
    setUnits("inches")
    setRackUnits(undefined)
    setRackDepth(undefined)
    setRackDepths(getRackDepths())
  }

  return (
    <Box pb="90px">
      <Box
        w="100%"
        mt="8px"
        p={bp(
          null,
          null,
          "0 calc((2 / 16) * 100vw)",
          "0 calc((4 / 16) * 100vw)"
        )}
      >
        <Flex
          direction={bp("column", "column", "row", "row")}
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            width={bp("calc(100% - 40px)", "calc(100% - 40px)", null, null)}
            marginTop={bp("20px", "20px", "10px", "10px")}
            flex={bp(null, null, "1", "1")}
            mr={bp(null, null, "10px", "10px")}
          >
            <SelectInput
              name="Units"
              label="Units"
              value={units}
              options={[
                { label: "inches", value: "inches" },
                { label: "cm", value: "cm" },
              ]}
              onChange={(e) => {
                setUnits(e.target.value)
              }}
            />
          </Flex>
          <Flex
            width={bp("calc(100% - 40px)", "calc(100% - 40px)", null, null)}
            marginTop={"10px"}
            flex={bp(null, null, "1", "1")}
            mr={bp(null, null, "10px", "10px")}
          >
            <SelectInput
              name="Rack Units"
              label="Rack Units"
              value={rackUnits}
              options={rackUnitOptions.map((v) => ({
                label: v.toString(),
                value: v,
              }))}
              onChange={onSelectUnits}
            />
          </Flex>
          <Flex
            width={bp("calc(100% - 40px)", "calc(100% - 40px)", null, null)}
            marginTop={"10px"}
            flex={bp(null, null, "1", "1")}
            mr={bp(null, null, "10px", "10px")}
          >
            <SelectInput
              name="Depth"
              label="Depth"
              value={rackDepth}
              options={rackDepths.map((v) => ({
                label:
                  units === "inches"
                    ? v.toString()
                    : (v * 2.54).toFixed(2).toString(),
                value: v,
              }))}
              onChange={(e) => {
                setRackDepth(parseFloat(e.target.value))
              }}
            />
          </Flex>
          <Flex
            width={bp(
              "calc(100% - 40px)",
              "calc(100% - 40px)",
              "127px",
              "127px"
            )}
            marginTop={"10px"}
            // flex={bp(null, null, "1", "1")}
            as="button"
            alignItems="center"
            justifyContent="center"
            h="58px"
            p={bp(null, null, "0 32px", "0 32px")}
            borderRadius="6px"
            bgColor={colors.dark}
            fontFamily="Avenir"
            fontWeight="800"
            fontSize="16px"
            lineHeight="58px"
            textAlign="center"
            textTransform="uppercase"
            color={colors.white}
            _disabled={{
              bgColor: colors.dark50,
              cursor: "not-allowed",
            }}
            disabled={!units || (!rackUnits && !rackDepth)}
            onClick={() => {
              if (units && rackUnits && rackDepth) {
                router.push(
                  getSearchByRackDimensionsUrl({ units, rackUnits, rackDepth })
                )
                onRequestClose()
                reset()
              } else if (units && !rackUnits && rackDepth) {
                router.push(getSearchByRackDepth({ units, rackDepth }))
                onRequestClose()
                reset()
              } else if (rackUnits && !rackDepth) {
                router.push(getSearchByRackUnits({ rackUnits }))
                onRequestClose()
                reset()
              }
            }}
          >
            Search
          </Flex>
        </Flex>
      </Box>
      <VerticalSpace height={62} />
      <Flex
        p={bp(
          null,
          null,
          "0 calc((2 / 16) * 100vw)",
          "0 calc((4 / 16) * 100vw)"
        )}
      >
        {image && (
          <Box
            position="relative"
            flexShrink={0}
            w={bp(
              "initial",
              null,
              "calc((2.50 / 16) * 100vw)",
              "calc((1.50 / 16) * 100vw)"
            )}
            mr={bp(
              "0",
              null,
              "calc((0.5 / 16) * 100vw)",
              "calc((0.25 / 16) * 100vw)"
            )}
          >
            <Image
              src={image}
              alt={"Search by Rack Dimensions"}
              layout="fill"
              objectFit="contain"
              objectPosition="top center"
            />
          </Box>
        )}
        <Box
          margin="0 20px 0 20px"
          textAlign={bp("center", "center", "left", "left")}
        >
          <StyledPortableText text={description} />
        </Box>
      </Flex>
    </Box>
  )
}

export default SearchByRackDimensions
