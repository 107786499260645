import React from "react"
import {
  createClient,
  createImageUrlBuilder,
  createPortableTextComponent,
  createPreviewSubscriptionHook,
} from "next-sanity"
import NumberedList from "@components/NumberedList"

const config = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || "",
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || "",
  apiVersion: "v2021-03-25",
  useCdn: process.env.NODE_ENV === "production",
  documentLimit: 20000,
}

const apiConfig = {
  ...config,
  token: process.env.NEXT_PUBLIC_SANITY_API_TOKEN || "",
  useCdn: false,
}

if (!config.projectId) {
  throw Error("The Project ID is not set. Check your environment variables.")
}

if (!config.dataset) {
  throw Error("The dataset name is not set. Check your environment variables.")
}

if (!apiConfig.token) {
  throw Error("The token is not set. Check your environment variables.")
}

export const apiClient = createClient(apiConfig)

export const sanityClient = createClient(config)

export const previewClient = createClient({
  ...config,
  useCdn: false,
})

export const getClient = (usePreview?: boolean) =>
  usePreview ? previewClient : sanityClient

export const urlFor = (source) => createImageUrlBuilder(config).image(source)

export const usePreviewSubscription = createPreviewSubscriptionHook(config)

export const PortableText = createPortableTextComponent({
  ...config,
  serializers: {
    types: {
      numberedList: (props) => <NumberedList {...props.node} />,
    },
  },
})
