import React from "react"
import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { PortableText } from "@utils/sanity"
import Image from "next/image"

import { Facebook, Instagram, Twitter, Youtube } from "../universal/icons"

import { bp, LaptopUpOnly } from "@utils/MediaQueries"

import Button from "@components/universal/Button"
import Heading from "../typography/Heading"
import { IconWrapper } from "../universal/icons/utils"
import Link from "@components/Link"
import Spacer from "../universal/Spacer"
import strings from "@constants/strings"
import colors from "@constants/colors"

import MobileFooter from "./mobileFooter"

export type Category = {
  title: string
  subcategories: { title: string; slug: string }[]
}

export type FooterData = {
  categories: Category[]
  contact: any
  copyright: string
  privacyPolicy: string
  terms: string
  facebookLink: string
  instagramLink: string
  twitterLink: string
  youtubeLink: string
  logo: string
}

export interface FooterProps {
  footerData: FooterData
}

export const PortableTextContainer = styled.div`
  font-family: "Avenir";
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: ${colors.midLight};

  p {
    margin-bottom: 2rem;
  }
`
export const Socials = ({
  facebookLink,
  instagramLink,
  twitterLink,
  youtubeLink,
}: Pick<
  FooterData,
  "facebookLink" | "twitterLink" | "instagramLink" | "youtubeLink"
>) => (
  <>
    <Link external href={twitterLink}>
      <IconWrapper h="2xl" w="2xl">
        <Twitter height="24px" width="24px" strokeColor="white" />
      </IconWrapper>
    </Link>
    <Link external href={instagramLink}>
      <IconWrapper h="2xl" w="2xl">
        <Instagram height="24px" width="24px" strokeColor="white" />
      </IconWrapper>
    </Link>
    <Link external href={facebookLink}>
      <IconWrapper h="2xl" w="2xl">
        <Facebook height="24px" width="24px" strokeColor="white" />
      </IconWrapper>
    </Link>
    <Link external href={youtubeLink}>
      <IconWrapper h="2xl" w="2xl">
        <Youtube height="24px" width="24px" strokeColor="white" />
      </IconWrapper>
    </Link>
  </>
)

const DesktopFooter: React.FC<FooterProps> = ({ footerData }) => {
  return (
    <LaptopUpOnly>
      <Box bg={colors.dark} pt="2xl" as="footer" color={colors.white}>
        <SimpleGrid
          columns={bp(2, null, 14)}
          display="flex"
          justifyContent="space-around"
          mt="1rem"
        >
          <Box gridColumn="2 / 4" gridRow="1">
            <Link href="/">
              <Button
                h="initial"
                tertiary
                borderRadius={0}
                flexShrink={0}
                p="0"
                width="84px"
                height="33.8px"
              >
                <Image
                  src={footerData.logo}
                  alt="SKB Logo"
                  quality={100}
                  loading="eager"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                />
              </Button>
            </Link>
          </Box>
          {footerData?.categories?.map((item, index) => (
            <Flex
              direction="column"
              key={item.title}
              alignItems="flex-start"
              gridColumn={`${(index + 2) * 2} / ${(index + 2) * 2 + 2}`}
              pb="6rem"
            >
              <Heading size="6" pb="lg">
                <Text fontFamily="Avenir" fontSize="19px" fontWeight="800">
                  {item.title}
                </Text>
              </Heading>
              {item.subcategories?.map((subItem, index) => {
                return (
                  <React.Fragment key={subItem.title}>
                    {!!index && <Spacer size="s" />}
                    <Link
                      href={subItem.slug!}
                      style={{ color: colors.midLight }}
                    >
                      <Text fontFamily="Avenir" fontSize="16px">
                        {subItem.title}
                      </Text>
                    </Link>
                  </React.Fragment>
                )
              })}
            </Flex>
          ))}
          <PortableTextContainer>
            {footerData?.contact && (
              <PortableText blocks={footerData.contact} />
            )}
          </PortableTextContainer>
        </SimpleGrid>
        <SimpleGrid
          columns={bp(2, null, 14)}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px="1rem"
        >
          <Flex color={colors.midLight}>
            <Text fontFamily="Avenir" fontSize="16px">
              {footerData.copyright}
            </Text>
          </Flex>
          <Flex>
            <Socials
              instagramLink={footerData.instagramLink}
              facebookLink={footerData.facebookLink}
              twitterLink={footerData.twitterLink}
              youtubeLink={footerData.youtubeLink}
            />
          </Flex>
          <Flex>
            <Link
              href={footerData.privacyPolicy}
              mr="2rem"
              style={{ color: colors.midLight }}
            >
              <Text fontFamily="Avenir" fontSize="16px">
                {strings.Footer.privacyPolicy}
              </Text>
            </Link>
            <Link
              href={footerData.terms}
              mr="2rem"
              style={{ color: colors.midLight }}
            >
              <Text fontFamily="Avenir" fontSize="16px">
                {strings.Footer.terms}
              </Text>
            </Link>
            <Button
              p="0"
              id="desktopTermlyButton"
              className="termly-display-preferences"
              variant="link"
            >
              <Text color="midLight" fontFamily="Avenir" fontSize="16px">
                Consent Preferences
              </Text>
            </Button>
          </Flex>
        </SimpleGrid>
      </Box>
    </LaptopUpOnly>
  )
}

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <>
      <MobileFooter {...props} />
      <DesktopFooter {...props} />
    </>
  )
}

export default Footer
