import React from "react"
import colors from "@constants/colors"
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Grid,
} from "@chakra-ui/react"
import { Close } from "@components/universal/icons"
import Button from "@components/universal/Button"
import SearchByKeyword from "./SearchByKeyword"
import SearchByGear from "./SearchByGear"
import SearchByCaseSize from "./SearchByCaseSize"
import SearchByRackDimensions from "./SearchByRackDimensions"
import { SearchParamProps } from "./common"
import { bp, LaptopUpOnly, MobileOnly } from "@utils/MediaQueries"
import _ from "lodash"
import Body from "@components/typography/Body"

const baseButtonProps = {
  mr: bp(null, null, null, "16px"),
  p: "16px",
  bgColor: "transparent",
  fontFamily: "Avenir",
  fontSize: "16px",
  fontWeight: "800",
  lineHeight: "20px",
}

export enum SearchTypes {
  BY_KEYWORD = "BY_KEYWORD",
  BY_GEAR = "BY_GEAR",
  BY_CASE_SIZE = "BY_CASE_SIZE",
  BY_RACK_DIMENSIONS = "BY_RACK_DIMENSIONS",
}

export interface SearchProps {
  isOpen: boolean
  searchConfig: {
    searchByKeywordLabel: string
    searchByKeywordPlaceholder: string
    popularSearchTerms: Array<string>
    searchByGearLabel: string
    searchByGearImage?: string
    searchByGearDescription: any
    searchByCaseSizeLabel: string
    searchByCaseSizeImage?: string
    searchByCaseSizeDescription: any
    searchByRackDimensionsLabel: string
    searchByRackDimensionsImage?: string
    searchByRackDimensionsDescription: any
  }
  searchParams: Array<SearchParamProps>
  rackDimensions: Array<{
    units: number
    depth: number
  }>
  productData: any
  collectionPaths: Array<Array<string>>
  fetchProducts: (query: Array<string>) => Promise<any>
  onRequestClose: () => void
}

export const Search: React.FC<SearchProps> = ({
  isOpen,
  searchConfig,
  searchParams,
  rackDimensions,
  productData,
  collectionPaths,
  fetchProducts,
  onRequestClose,
}) => {
  const handleClose = () => {
    if (isOpen) {
      onRequestClose()
    }
  }

  const [searchType, setSearchType] = React.useState<SearchTypes>(
    SearchTypes.BY_KEYWORD
  )

  return (
    <Box>
      {isOpen && (
        <Drawer placement={"top"} onClose={handleClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody paddingLeft="0px" paddingRight="0px">
              <Box
                position="relative"
                height="100%"
                pt={bp(
                  0,
                  0,
                  "calc((0.5 / 16) * 100vw)",
                  "calc((0.5 / 16) * 100vw)"
                )}
              >
                <LaptopUpOnly>
                  <Button
                    zIndex="1"
                    position="absolute"
                    top="calc((0.5 / 16) * 100vw)"
                    right="calc((0.5 / 16) * 100vw)"
                    w="52px"
                    h="52px"
                    p="16px"
                    bgColor="transparent"
                    onClick={handleClose}
                  >
                    <Close />
                  </Button>
                </LaptopUpOnly>
                <MobileOnly>
                  <Box
                    alignContent="center"
                    justifyContent="center"
                    display="flex"
                  >
                    <Button
                      zIndex="1"
                      onClick={handleClose}
                      display="flex"
                      h="52px"
                      flexDirection="row"
                    >
                      <Body
                        fontFamily="Avenir"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="800"
                        lineHeight="20px"
                        letterSpacing="0em"
                        textAlign="left"
                      >
                        {"Close"}
                      </Body>
                      <Box marginTop="1px" marginLeft="5px" w="12px" h="12px">
                        <Close strokeWidth="2" opacity="1" />
                      </Box>
                    </Button>
                  </Box>
                </MobileOnly>
                <Grid
                  borderTop={bp(
                    "1px solid rgba(177,181,184,.3)",
                    "1px solid rgba(177,181,184,.3)",
                    "1px hidden transparent",
                    "1px hidden transparent"
                  )}
                  borderBottom={bp(
                    "1px solid rgba(177,181,184,.3)",
                    "1px solid rgba(177,181,184,.3)",
                    "1px hidden transparent",
                    "1px hidden transparent"
                  )}
                  width="100vw"
                  overflowX="auto"
                  gridTemplateColumns="repeat(12, 1fr)"
                >
                  <Box
                    cursor="pointer"
                    whiteSpace="nowrap"
                    {...baseButtonProps}
                    gridColumn={5}
                    gridRow={1}
                    height="100%"
                    display="grid"
                    alignContent="center"
                    justifyContent="center"
                    color={
                      searchType === SearchTypes.BY_KEYWORD
                        ? colors.dark
                        : colors.dark50
                    }
                    onClick={() => {
                      if (searchType !== SearchTypes.BY_KEYWORD) {
                        setSearchType(SearchTypes.BY_KEYWORD)
                      }
                    }}
                  >
                    {_.get(
                      searchConfig,
                      "searchByKeywordLabel",
                      "Search by Keyword"
                    )}
                  </Box>
                  <Box
                    cursor="pointer"
                    whiteSpace="nowrap"
                    {...baseButtonProps}
                    gridColumn={6}
                    gridRow={1}
                    height="100%"
                    display="grid"
                    alignContent="center"
                    justifyContent="center"
                    color={
                      searchType === SearchTypes.BY_GEAR
                        ? colors.dark
                        : colors.dark50
                    }
                    onClick={() => {
                      if (searchType !== SearchTypes.BY_GEAR) {
                        setSearchType(SearchTypes.BY_GEAR)
                      }
                    }}
                  >
                    {_.get(searchConfig, "searchByGearLabel", "Search by Gear")}
                  </Box>
                  <Box
                    cursor="pointer"
                    whiteSpace="nowrap"
                    {...baseButtonProps}
                    gridColumn={7}
                    gridRow={1}
                    height="100%"
                    display="grid"
                    alignContent="center"
                    justifyContent="center"
                    color={
                      searchType === SearchTypes.BY_CASE_SIZE
                        ? colors.dark
                        : colors.dark50
                    }
                    onClick={() => {
                      if (searchType !== SearchTypes.BY_CASE_SIZE) {
                        setSearchType(SearchTypes.BY_CASE_SIZE)
                      }
                    }}
                  >
                    {_.get(
                      searchConfig,
                      "searchByCaseSizeLabel",
                      "Search by Case Size"
                    )}
                  </Box>
                  <Box
                    cursor="pointer"
                    whiteSpace="nowrap"
                    {...baseButtonProps}
                    gridColumn={8}
                    gridRow={1}
                    height="100%"
                    display="grid"
                    alignContent="center"
                    justifyContent="center"
                    color={
                      searchType === SearchTypes.BY_RACK_DIMENSIONS
                        ? colors.dark
                        : colors.dark50
                    }
                    onClick={() => {
                      if (searchType !== SearchTypes.BY_RACK_DIMENSIONS) {
                        setSearchType(SearchTypes.BY_RACK_DIMENSIONS)
                      }
                    }}
                  >
                    {_.get(
                      searchConfig,
                      "searchByRackDimensionsLabel",
                      "Search by Rack Dimensions"
                    )}
                  </Box>
                </Grid>
                <Box height="100%">
                  {searchType === SearchTypes.BY_KEYWORD && (
                    <SearchByKeyword
                      placeholder={_.get(
                        searchConfig,
                        "searchByKeywordPlaceholder",
                        ""
                      )}
                      popularSearchTerms={_.get(
                        searchConfig,
                        "popularSearchTerms",
                        []
                      )}
                      collectionPaths={collectionPaths}
                      productData={productData}
                      fetchProducts={fetchProducts}
                      onRequestClose={handleClose}
                    />
                  )}

                  {searchType === SearchTypes.BY_GEAR && (
                    <SearchByGear
                      searchParams={searchParams || []}
                      image={_.get(searchConfig, "searchByGearImage")}
                      description={_.get(
                        searchConfig,
                        "searchByGearDescription",
                        []
                      )}
                      onRequestClose={handleClose}
                    />
                  )}

                  {searchType === SearchTypes.BY_CASE_SIZE && (
                    <SearchByCaseSize
                      image={_.get(searchConfig, "searchByCaseSizeImage")}
                      description={_.get(
                        searchConfig,
                        "searchByCaseSizeDescription",
                        []
                      )}
                      onRequestClose={handleClose}
                    />
                  )}

                  {searchType === SearchTypes.BY_RACK_DIMENSIONS && (
                    <SearchByRackDimensions
                      rackDimensions={rackDimensions}
                      image={_.get(searchConfig, "searchByRackDimensionsImage")}
                      description={_.get(
                        searchConfig,
                        "searchByRackDimensionsDescription",
                        []
                      )}
                      onRequestClose={handleClose}
                    />
                  )}
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  )
}

export default Search
