import React from "react"
import styled from "@emotion/styled"
import { Box, BoxProps, Text } from "@chakra-ui/react"
import colors from "@constants/colors"
import { bp } from "@utils/MediaQueries"

const Indicator = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: ${colors.brand};
`

const IndicatorText = styled.span`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  color: ${colors.white};
`
export interface NumberedListProps
  extends Omit<BoxProps, "children" | "title"> {
  header: string
  items: Array<{
    title: string
    description: string
  }>
}

const NumberedList: React.FC<NumberedListProps> = ({
  header,
  items,
  ...rest
}) => {
  const getIndicatorText = (i) => {
    return i.toString().length > 1 ? i : "0" + i.toString()
  }

  return (
    <Box>
      <Text
        fontFamily="Avenir"
        fontSize={bp("23px", null, "33px")}
        fontWeight="800"
      >
        {header}
      </Text>
      {items?.map((item, idx) => (
        <Box key={`${item.title}-${idx}`}>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            mt="2rem"
            mb="1rem"
          >
            <Indicator>
              <IndicatorText>{getIndicatorText(idx + 1)}</IndicatorText>
            </Indicator>
            <Text
              fontFamily="Avenir"
              fontSize={bp("16px", null, "19px")}
              fontWeight="800"
              ml="8px"
              mb="0 !important"
            >
              {item.title}
            </Text>
          </Box>
          <Text
            fontFamily="Avenir"
            fontSize={bp("16px", null, "19px")}
            fontWeight="400"
            color={colors.midAccessible}
          >
            {item.description}
          </Text>
        </Box>
      ))}
    </Box>
  )
}

export default NumberedList
