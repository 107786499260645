import React from "react"
import { useRouter } from "next/router"
import { Box } from "@chakra-ui/react"
import SeoContext from "./SeoContext"
import Header from "@components/Header"
import Footer from "@components/Footer"
import Spacer from "@components/universal/Spacer"
import MiniCart from "@rs/MiniCart"
import ZipCodeModal from "@rs/ZipCodeModal"
import useInterval from "@hooks/useInterval"
import { bp } from "@utils/MediaQueries"
import {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "@constants/measurements"

interface LayoutProps {
  seoData: any
  headerData: any
  menuData: any
  productData: any
  searchData: any
  footerData: any
  children: JSX.Element | JSX.Element[]
}

const Layout: React.FC<LayoutProps> = ({
  seoData,
  headerData,
  menuData,
  productData,
  searchData,
  footerData,
  children,
}) => {
  footerData["logo"] = headerData.logo

  const router = useRouter()
  const [termlyButtonRendered, setTermlyButtonRendered] = React.useState(false)
  const [termlyScriptRendered, setTermlyScriptRendered] = React.useState(false)

  const selectTermlyButton = () => {
    const desktopTermlyButton = document.getElementById("desktopTermlyButton")
    const mobileTermlyButton = document.getElementById("mobileTermlyButton")
    return desktopTermlyButton && mobileTermlyButton
  }

  useInterval(() => {
    if (!termlyButtonRendered) {
      const termlyButton = selectTermlyButton()

      if (termlyButton) {
        setTermlyButtonRendered(true)
      }
    }
  }, 1000)

  React.useEffect(() => {
    const resetTermlyScript = () => {
      const termlyScript = document.getElementById("termlyScript")
      if (termlyScript) {
        // @ts-ignore
        window.TERMLY_RESOURCE_BLOCKER_LOADED = undefined
        termlyScript.remove()
        setTermlyScriptRendered(false)
        setTermlyButtonRendered(false)
      }
    }

    router.events.on("routeChangeComplete", resetTermlyScript)

    return () => {
      router.events.off("routeChangeComplete", resetTermlyScript)
    }
  }, [])

  React.useEffect(() => {
    function loadTermlyScript(FILE_URL, async = "true") {
      let scriptEle = document.createElement("script")

      scriptEle.setAttribute("id", "termlyScript")
      scriptEle.setAttribute("src", FILE_URL)
      scriptEle.setAttribute("type", "text/javascript")
      scriptEle.setAttribute("async", async)
      document.body.appendChild(scriptEle)

      scriptEle.addEventListener("load", () => {
        setTermlyScriptRendered(true)
        console.log("Termly loaded")
      })
      scriptEle.addEventListener("error", (ev) => {
        console.log("Termly failed to load:", ev)
      })
    }

    if (termlyButtonRendered && !termlyScriptRendered) {
      const termlyScript = document.getElementById("termlyScript")

      if (!termlyScript) {
        loadTermlyScript(
          "https://app.termly.io/resource-blocker/4e32984a-cacf-4879-96b9-2b8e65c6dd90?autoBlock=off",
          "true"
        )
      }
    }
  }, [termlyButtonRendered])

  return (
    <SeoContext.Provider value={{ seoData }}>
      <Box minH="100vh" width="100%" display="flex" flexDirection="column">
        <MiniCart />
        <ZipCodeModal />
        <Header
          headerData={headerData}
          menuData={menuData}
          productData={productData}
          searchData={searchData}
        />
        <Box as="main" w="100%" h="100%" flexGrow={1}>
          <Spacer h={bp(MOBILE_HEADER_HEIGHT, null, DESKTOP_HEADER_HEIGHT)} />
          {children}
        </Box>
        <Box id="endOfContent" />
        <Footer footerData={footerData} />
      </Box>
    </SeoContext.Provider>
  )
}

export default Layout
