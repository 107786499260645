import React, { useEffect, useMemo, useRef, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Box, Flex, Text } from "@chakra-ui/react"
import colors from "@constants/colors"
import Button from "@components/universal/Button"
import Heading from "@components/typography/Heading"
import Link from "@components/Link"
import Spacer from "@components/universal/Spacer"
import { MenuRecord } from "./common"
import useClickHandler from "@hooks/useClickHandler"
import useResizeHandler from "@hooks/useResizeHandler"
import { MotionBox } from "@utils/FramerMotion"
import { bp } from "@utils/MediaQueries"
import { formatUrl } from "@utils/urls"

const Column = styled.div<{ useFiveColumn?: boolean }>`
  ${({ useFiveColumn }) => css`
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    width: calc(${useFiveColumn ? 2.6 : 3.25} / 16 * 100vw);
    padding: calc(0.25 / 16 * 100vw);

    @media only screen and (min-width: 90rem) {
      width: calc(${useFiveColumn ? 2.2 : 2.5} / 16 * 100vw);
      padding: calc(0.5 / 16 * 100vw);
    }
  `}
`

const AlignedColumn = styled.div<{ xPosition }>`
  ${({ xPosition }) => css`
    position: absolute;
    top: 0;
    left: ${xPosition}px;
    display: flex;
    width: calc(3.25 / 16 * 100vw);
    padding-top: calc(0.25 / 16 * 100vw);
    padding-left: 20px;

    @media only screen and (min-width: 1024px) {
      width: calc(2.5 / 16 * 100vw);
      padding-top: calc(0.5 / 16 * 100vw);
    }
  `}
`

const Ruler = styled.div`
  display: flex;
  opacity: 0;
  width: calc(3.25 / 16 * 100vw);
  padding: calc(0.25 / 16 * 100vw);
  pointer-events: none;

  @media only screen and (min-width: 1024px) {
    width: calc(2.5 / 16 * 100vw);
    padding: calc(0.5 / 16 * 100vw);
  }
`

export interface DesktopNavMenuProps {
  data: MenuRecord[]
  bgColor?: string
  fontColor?: string
}

export interface FormattedMenuData {
  title: string
  slug?: string
  url?: string
  alignedCol: MenuRecord[]
  colLeft: MenuRecord[]
  colMidLeft: MenuRecord[]
  colMid: MenuRecord[]
  colMidRight: MenuRecord[]
  colRight: MenuRecord[]
}

// Hard-coded limit of 10 menu items
// TODO: Set as an env var
const buttonRefs: any = [...Array(10)].map(() => React.createRef())

const renderItem = (item, setCategory, useFiveColumn) => (
  <Flex
    key={item.title}
    direction="column"
    alignItems="flex-start"
    width="1p5col"
    pb="2rem"
  >
    {item.slug ? (
      <Link
        onClick={() => setCategory(undefined)}
        href={item.slug || "/"}
        color="dark"
        pb="lg"
      >
        <Heading size="md" as="span">
          {item.title}
        </Heading>
      </Link>
    ) : (
      <Heading size="md" pb="lg">
        {item.title}
      </Heading>
    )}
    {item.subcategories?.map((subItem, index) => (
      <React.Fragment key={subItem.title}>
        {!!index && <Spacer size="s" />}
        <Link onClick={() => setCategory(undefined)} href={subItem.slug || "/"}>
          <Text fontFamily="Avenir" fontSize="14px">
            {subItem.title}
          </Text>
        </Link>
      </React.Fragment>
    ))}
  </Flex>
)

const renderAlignedColumnContent = (item, setCategory) => {
  return (
    <Flex direction="column">
      {item.slug ? (
        <Link
          onClick={() => {
            setCategory(undefined)
          }}
          href={item.slug || "/"}
          color="dark"
          pb="lg"
        >
          <Heading size="md" as="span">
            {item.title}
          </Heading>
        </Link>
      ) : (
        <Heading size="md" pb="lg">
          {item.title}
        </Heading>
      )}
      {item.subcategories?.map((subItem, index) => (
        <React.Fragment key={subItem.title}>
          {!!index && <Spacer size="s" />}
          <Link
            onClick={() => {
              setCategory(undefined)
            }}
            href={subItem.slug || "/"}
          >
            <Text fontFamily="Avenir" fontSize="14px">
              {subItem.title}
            </Text>
          </Link>
        </React.Fragment>
      ))}
    </Flex>
  )
}

export const DesktopNavMenu: React.FC<DesktopNavMenuProps> = ({
  data,
  bgColor,
  fontColor,
}) => {
  const [category, setCategory] = useState<number | undefined>()
  const flexRef = useRef<HTMLDivElement>(null)

  const handleClose = (event) => {
    if (flexRef.current && !flexRef.current.contains(event.target)) {
      setCategory(undefined)
    }
  }

  useClickHandler({
    onClick: handleClose,
  })

  const [height, setHeight] = useState(0)
  const navSectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setHeight(navSectionRef.current?.clientHeight || 0)
  }, [data, flexRef, category])

  const buttonProps = {
    tertiary: true,
    borderRadius: 0,
    p: "lg",
    h: "100%",
    fontFamily: "Avenir",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "20px",
  }

  const NavButtons = useMemo(() => {
    return data.map((item, index) => {
      const linkProps: any = {}
      if (item.slug) {
        linkProps.as = "a"
        linkProps.href = formatUrl(item.slug)
      }
      return (
        <Button
          ref={buttonRefs[index]}
          key={item.title}
          {...buttonProps}
          {...linkProps}
          _hover={{
            borderBottom: `1px solid ${colors.dark}`,
          }}
          _focus={{ border: "0px solid transparent" }}
          color={fontColor}
          onMouseEnter={() => setCategory(index)}
        >
          {item.title}
        </Button>
      )
    })
  }, [data, buttonRefs])

  const menuData = React.useMemo(() => {
    return data.reduce((results: FormattedMenuData[], category) => {
      const thisCategory: FormattedMenuData = {
        title: category.title,
        alignedCol: [],
        colLeft: [],
        colMidLeft: [],
        colMid: [],
        colMidRight: [],
        colRight: [],
      }

      if (category.subcategories?.length === 1) {
        thisCategory.alignedCol.push(category.subcategories[0])
      } else if (category.useFiveColumns) {
        category.subcategories?.forEach((subcategory, i) => {
          switch (i % 5) {
            case 0:
              thisCategory.colLeft.push(subcategory)
              return
            case 1:
              thisCategory.colMidLeft.push(subcategory)
              return
            case 2:
              thisCategory.colMid.push(subcategory)
              return
            case 3:
              thisCategory.colMidRight.push(subcategory)
              return
            case 4:
              thisCategory.colRight.push(subcategory)
              return
          }
        })
      } else {
        category.subcategories?.forEach((subcategory, i) => {
          switch (i % 4) {
            case 0:
              thisCategory.colLeft.push(subcategory)
              return
            case 1:
              thisCategory.colMidLeft.push(subcategory)
              return
            case 2:
              thisCategory.colMidRight.push(subcategory)
              return
            case 3:
              thisCategory.colRight.push(subcategory)
              return
          }
        })
      }

      results.push(thisCategory)
      return results
    }, [])
  }, [data])

  const [buttonXPositions, setButtonXPositions] = useState<any>([])

  const updateButtonXPositions = () => {
    const xPositions: Number[] = []

    buttonRefs.forEach((buttonRef, i) => {
      if (
        buttonRef &&
        buttonRef.current && [
          buttonXPositions[i] !== buttonRef.current.getBoundingClientRect().x,
        ]
      ) {
        xPositions.push(buttonRef.current.getBoundingClientRect().x)
      }
    })

    setButtonXPositions(xPositions)
  }

  useEffect(() => {
    updateButtonXPositions()
  }, [NavButtons])

  useResizeHandler({ onResize: updateButtonXPositions })

  return (
    <Box width="100%" height="100%">
      <Box
        onMouseEnter={() => setCategory(undefined)}
        position="fixed"
        left="0"
        top="0"
        bg={"transparent"}
        width="100vw"
        height="10px"
      />
      <Flex
        ref={flexRef}
        position="relative"
        alignItems="center"
        justifyContent="center"
        h="100%"
        bg={"transparent"}
      >
        {NavButtons}
        <MotionBox
          position="fixed"
          zIndex={-1}
          top={bp("4rem", null, "4.375rem", "4rem")}
          left="0"
          w="100%"
          overflowY="hidden"
          borderBottom="1px hidden"
          borderColor="light"
          animate={{
            height,
          }}
          bg="white"
          boxShadow="lg"
          //@ts-ignore
          transition={{ type: "spring", duration: 0.4 }}
        >
          <Box overflowY="auto" width="100%" height="calc( 100vh - 65px )">
            {category !== undefined && (
              <Flex
                ref={navSectionRef}
                onMouseLeave={() => setCategory(undefined)}
              >
                {menuData[category].alignedCol?.length > 0 ? (
                  <>
                    <AlignedColumn xPosition={buttonXPositions[category]}>
                      {renderAlignedColumnContent(
                        menuData[category].alignedCol[0],
                        setCategory
                      )}
                    </AlignedColumn>
                    <Ruler>
                      {renderAlignedColumnContent(
                        menuData[category].alignedCol[0],
                        setCategory
                      )}
                    </Ruler>
                  </>
                ) : (
                  <Flex
                    p={{
                      base:
                        menuData[category].colMid.length > 0
                          ? "0 calc(1.5 / 16 * 100vw)"
                          : "0 calc(2 / 16 * 100vw)",
                      xl:
                        menuData[category].colMid.length > 0
                          ? "0 calc(2.5 / 16 * 100vw)"
                          : "0 calc(3 / 16 * 100vw)",
                    }}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    onMouseLeave={() => setCategory(undefined)}
                  >
                    <Column
                      useFiveColumn={menuData[category].colMid.length > 0}
                    >
                      {menuData[category].colLeft.map((item) =>
                        renderItem(
                          item,
                          setCategory,
                          menuData[category].colMid.length > 0
                        )
                      )}
                    </Column>
                    <Column
                      useFiveColumn={menuData[category].colMid.length > 0}
                    >
                      {menuData[category].colMidLeft.map((item) =>
                        renderItem(
                          item,
                          setCategory,
                          menuData[category].colMid.length > 0
                        )
                      )}
                    </Column>
                    {menuData[category].colMid.length > 0 && (
                      <Column useFiveColumn>
                        {menuData[category].colMid.map((item) =>
                          renderItem(item, setCategory, true)
                        )}
                      </Column>
                    )}
                    <Column
                      useFiveColumn={menuData[category].colMid.length > 0}
                    >
                      {menuData[category].colMidRight.map((item) =>
                        renderItem(
                          item,
                          setCategory,
                          menuData[category].colMid.length > 0
                        )
                      )}
                    </Column>
                    <Column
                      useFiveColumn={menuData[category].colMid.length > 0}
                    >
                      {menuData[category].colRight.map((item) =>
                        renderItem(
                          item,
                          setCategory,
                          menuData[category].colMid.length > 0
                        )
                      )}
                    </Column>
                  </Flex>
                )}
              </Flex>
            )}
          </Box>
        </MotionBox>
      </Flex>
    </Box>
  )
}

export default DesktopNavMenu
