import React, { ReactText, useState } from "react"
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
} from "@chakra-ui/react"
import colors from "@constants/colors"
import { Chevron } from "@components/universal/icons"
import ListItem from "@components/universal/ListItem"
import Body from "@components/typography/Body"
import { MenuRecord } from "./common"
import { getIn } from "@utils/index"
import { formatUrl } from "@utils/urls"
import _ from "lodash"

export interface MobileNavMenuProps {
  data: MenuRecord[]
  isOpen: boolean
  onClose: () => void
}

const isStringEmpty = (str: string) => {
  return !str || str.trim().length === 0
}

export const MobileNavMenu: React.FC<MobileNavMenuProps> = ({
  data,
  isOpen,
  onClose,
}) => {
  const [path, setPath] = useState<ReactText[]>([])
  const closeWrapper = () => {
    onClose()
    setPath([])
  }

  const orderedMenuData = React.useMemo(() => {
    return data.map((navGroup) => {
      const subgroupCategoriesWithOrderedItems = navGroup.subcategories?.map(
        (subcategory) => {
          return {
            ...subcategory,
            subcategories: _.sortBy(subcategory.subcategories, "mobileIndex"),
          }
        }
      )

      return {
        ...navGroup,
        subcategories: _.sortBy(
          subgroupCategoriesWithOrderedItems,
          "mobileIndex"
        ),
      }
    })
  }, [data])

  return (
    <Drawer placement="right" onClose={closeWrapper} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent maxW="80vw">
        <DrawerCloseButton
          borderRadius={0}
          top={0}
          right={0}
          w="2xl"
          h="2xl"
          _focus={{ outline: "none" }}
          _hover={{ bg: "light" }}
          _active={{ bg: "lightDarker" }}
        />
        <DrawerBody pt="2xl" px={0}>
          {path.length > 0 && (
            <Flex
              bg="light"
              p="1rem 1.25rem"
              onClick={() => setPath((prev) => prev.slice(0, -2))}
              title="Go Back"
            >
              <Chevron
                width="1.25rem"
                height="1.25rem"
                strokeColor={colors.midAccessible}
                style={{ transform: "rotate(180deg)" }}
              />
              <Body color="midAccessible" ml="m">
                {getIn(orderedMenuData, path.slice(0, -1))?.title}
              </Body>
            </Flex>
          )}
          {getIn(orderedMenuData, path)?.map(
            (item: MenuRecord, index: number) => {
              const isCategory =
                item.subcategories && item.subcategories.length !== 0
              const itemProps = {} as any
              if (item.slug) {
                itemProps.href = formatUrl(item.slug)
              }
              return isStringEmpty(item.title) === false ? (
                <ListItem
                  px="lg"
                  bg="transparent"
                  key={item.title}
                  title={item.title}
                  chevron={isCategory}
                  titleProps={{ fontWeight: isCategory ? "medium" : "normal" }}
                  onClick={
                    isCategory
                      ? () =>
                          setPath((prev) => [...prev, index, "subcategories"])
                      : item.slug
                      ? () => {
                          closeWrapper()
                        }
                      : undefined
                  }
                  _focus={{
                    outline: "none",
                    boxShadow: `0 0 0 3px ${colors.focus}`,
                  }}
                  as={
                    item.subcategories === undefined ||
                    item.subcategories?.length === 0
                      ? "a"
                      : "button"
                  }
                  {...itemProps}
                />
              ) : (
                item.subcategories?.map(({ slug, title }) => {
                  const itemProps = {} as any
                  if (slug) {
                    itemProps.href = formatUrl(slug)
                  }
                  return (
                    <ListItem
                      px="lg"
                      bg="transparent"
                      key={title}
                      title={title}
                      titleProps={{
                        fontWeight: isCategory ? "medium" : "normal",
                      }}
                      onClick={
                        slug
                          ? () => {
                              closeWrapper()
                            }
                          : undefined
                      }
                      _focus={{
                        outline: "none",
                        boxShadow: `0 0 0 3px ${colors.focus}`,
                      }}
                      as={slug ? "a" : "button"}
                      {...itemProps}
                    />
                  )
                })
              )
            }
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default MobileNavMenu
