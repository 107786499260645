import _ from "lodash"

export const formatUrl = (url: string): string => {
  if (url) {
    if (url[0] === "#") return url
    return url[0] !== "/" ? `/${url}` : url
  }
  return "/bad"
}

export const getSearchByKeywordUrl = (query: string): string =>
  `/search/by-keyword/query=${encodeURIComponent(query)}`

export const getSearchByGearUrl = ({
  category,
  brand,
  model,
}: {
  category: string
  brand: string
  model: string
}): string =>
  `/search/by-gear/category=${encodeURIComponent(
    category
  )}&brand=${encodeURIComponent(brand)}&model=${encodeURIComponent(model)}`

export const getSearchByCaseSizeUrl = ({
  units,
  length,
  width,
  depth,
}: {
  units: string
  length: number
  width: number
  depth: number
}): string =>
  `/search/by-case-size/units=${encodeURIComponent(
    units
  )}&length=${encodeURIComponent(length)}&width=${encodeURIComponent(
    width
  )}&depth=${encodeURIComponent(depth)}`

export const getSearchByRackDimensionsUrl = ({
  units,
  rackUnits,
  rackDepth,
}: {
  units: string
  rackUnits: number
  rackDepth: number
}): string =>
  `/search/by-rack-dimensions/units=${encodeURIComponent(
    units
  )}&rackUnits=${encodeURIComponent(rackUnits)}&rackDepth=${encodeURIComponent(
    rackDepth
  )}`

export const getSearchByRackDepth = ({
  units,
  rackDepth,
}: {
  units: string
  rackDepth: number
}): string =>
  `/search/by-rack-depth/units=${encodeURIComponent(
    units
  )}&rackDepth=${encodeURIComponent(rackDepth)}`

export const getSearchByRackUnits = ({
  rackUnits,
}: {
  rackUnits: number
}): string => `/search/by-rack-units/rackUnits=${encodeURIComponent(rackUnits)}`

export const getCollectionUrl = (collectionSlug: string): string =>
  `/collections/${collectionSlug}`

export const getProductUrl = (productSlug: string): string =>
  `/products/${productSlug}`

export const getFullProductUrl = (productSlug: string): string =>
  `https://www.skbcases.com/products/${productSlug}`
