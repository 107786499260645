import React from "react"
import colors from "@constants/colors"
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react"

export interface ButtonProps extends ChakraButtonProps {
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  link?: boolean
}

const Button = React.forwardRef(
  (
    { children, primary, secondary, tertiary, link, ...rest }: ButtonProps,
    ref
  ) => {
    const baseStyles: Omit<ChakraButtonProps, "children"> = {
      p: "1rem 1.5rem",
      h: "initial",
      borderRadius: ".25rem",
      fontWeight: "medium",
      fontFamily: "helvetica",
      _focus: {
        boxShadow: `0 0 0 0px ${colors.focus}`,
      },
    }

    let styles: Omit<ChakraButtonProps, "children"> = {}

    if (primary) {
      styles = {
        color: "white",
        backgroundColor: "primary",
        _hover: {
          backgroundColor: "situational1",
        },
        _active: {
          backgroundColor: "situational2",
        },
      }
    } else if (secondary) {
      styles = {
        color: "primary",
        backgroundColor: "situational3",
        _hover: {
          backgroundColor: "situational4",
        },
        _active: {
          backgroundColor: "situational5",
        },
      }
    } else if (tertiary) {
      styles = {
        color: "primary",
        variant: "ghost",
        _hover: {
          backgroundColor: "light",
        },
        _active: {
          backgroundColor: "lightDarker",
        },
      }
    } else if (link) {
      styles = {
        color: "primary",
        variant: "link",
        _hover: {
          textDecoration: "underline",
        },
        _active: {
          textDecoration: "underline",
        },
      }
    }

    return (
      <ChakraButton {...baseStyles} {...styles} {...rest} ref={ref as any}>
        {children}
      </ChakraButton>
    )
  }
)

export default Button
