import React from "react"

const MiniCart: React.FC<any> = () => {
  const [isMounted, setIsMounted] = React.useState(false)

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  return !isMounted ? null : (
    <div
      suppressHydrationWarning
      className="rsModalWrapper rsMiniCartModalWrapper rsHidden"
    >
      <div className="rsModalOverlay"></div>
      <div className="rsModal" aria-labelledby="rsMiniCartModalTitle">
        <div className="rsModalSubheader">
          <span className="rsCheckIcon"></span>
          <span
            className="rsItemAddedToCartText rsModalTitle"
            id="rsMiniCartModalTitle"
          >
            Item added to cart
          </span>
          <span className="rsModalCloseButton rsModalCloseX">×</span>
        </div>
        <div className="rsModalBody">
          <div className="rsMiniCartContainer">
            <div className="rsProductInfoContainer">
              <div className="rsProductImageWrapper">
                <img className="rsProductImage" />
              </div>
              <div className="rsProductInfo">
                <div className="rsProductTitle"></div>
                <div className="rsProductInfoFooter">
                  <div className="rsPriceWrapper">
                    {" "}
                    <span className="rsPrice"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="rsMiniCartSummaryContainer">
              <div className="rsMiniCartSummary">
                <span>
                  <span className="rsCartIcon"></span>
                  <span>Subtotal</span>
                </span>
                <span>
                  <span className="rsCartCount"></span>
                  {" | "}
                  <span className="rsCartSubtotal"></span>
                </span>
              </div>
              <a className="rsModalButton rsModalCloseButton">
                Continue Shopping
              </a>
              <a className="rsViewCartButton rsModalButton">View Cart</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiniCart
