import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import colors from "@constants/colors"
import { typographyHelperCss } from "@constants/typography"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"

const StyledTextField = styled(TextField)<any>`
  ${({ error }) => css`
    width: 100%;
    height: 58px;
    border-radius: 6px;
    background-color: ${error ? colors.errorLightest : colors.light};

    :hover {
      background-color: ${colors.lightDarker};
    }
    :active {
      background-color: ${colors.lightDarkest};
    }

    .MuiInput-input {
      ${typographyHelperCss("bodyNormal")}
      height: 100%;
      padding: 8px 16px 0;
      padding: 0.5rem 1rem 0;
      color: ${error ? colors.error : colors.dark};
    }

    .MuiInput-input::placeholder {
      opacity: 0;
    }

    .MuiInput-underline:before {
      border: none;
    }

    && .MuiInput-underline:hover:before {
      border: none;
    }

    .MuiInput-underline:after {
      border: none;
    }

    .MuiInputLabel-root {
      ${typographyHelperCss("bodyNormal")}
      z-index: 1;
      left: 1rem;
      opacity: 1;
      transform: translate(0, 1.25rem) scale(1);
      color: ${error ? colors.error : colors.midLight};
      pointer-events: none;
    }

    && .MuiInputLabel-shrink {
      ${typographyHelperCss("bodyNormal")}
      transform: translate(0, 6px) scale(0.8);
      color: ${error ? colors.errorLight : colors.midLight};

      &.Mui-focused {
        color: ${error ? colors.errorLight : colors.midLight};
      }
    }

    .MuiFormHelperText-root {
      ${typographyHelperCss("bodyXs")}
      margin-top: 1.25rem;
      padding-left: 1rem;
      color: ${error ? colors.error : colors.midLight};
    }
  `}
`

const TextInput = (props: TextFieldProps) => {
  return <StyledTextField {...props} />
}

export default TextInput
