import { ReactText } from "react"
import _ from "lodash"
import * as R from "ramda"

export const isNilOrEmpty = (a: any): any => R.isNil(a) || R.isEmpty(a)

export const getIn = (
  o: object,
  path: ReactText[] | string,
  defaultValue?: any
) =>
  (o &&
    path &&
    R.view(R.lensPath(typeof path === "string" ? path.split(".") : path))(o)) ||
  defaultValue

export const sortByIndex = (arr, prop, order) => {
  return _.sortBy(arr, (d) => {
    const index = _.indexOf(order, d[prop])
    return index === -1 ? arr.length : index
  })
}

export const toTitle: any = R.compose(
  R.join(""),
  R.over(R.lensIndex(0), R.toUpper)
)

export const toggleKeyInArray: any = (key, keys = []) => {
  return !R.isEmpty(keys) && R.contains(key, keys)
    ? keys.filter((arrKey) => arrKey !== key)
    : [...keys, key]
}
