import React from "react"
import { useRouter } from "next/router"
import { Box, Flex, Grid, Text } from "@chakra-ui/react"
import colors from "@constants/colors"
import Link from "@components/Link"
import Card, { CardProps } from "./Card"
import SearchInput from "@components/form/inputs/SearchInput"
import { bp } from "@utils/MediaQueries"
import { getSearchByKeywordUrl, getCollectionUrl } from "@utils/urls"
import { matchSorter } from "match-sorter"
import _ from "lodash"

const ESCAPE_KEY = "Escape"
const ENTER_KEY = "Enter"

const searchKeys = [
  { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: "productName" },
  { threshold: matchSorter.rankings.CONTAINS, key: "sku" },
  { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: "tags" },
  { threshold: matchSorter.rankings.CONTAINS, key: "variants.*.sku" },
]

export interface SearchByKeywordProps {
  placeholder: string
  popularSearchTerms: Array<string>
  collectionPaths: Array<Array<string>>
  productData: any
  fetchProducts: (query: Array<string>) => Promise<any>
  onRequestClose: () => void
}

const SearchByKeyword: React.FC<SearchByKeywordProps> = ({
  placeholder,
  popularSearchTerms,
  collectionPaths,
  productData,
  fetchProducts,
  onRequestClose,
}) => {
  const router = useRouter()
  const [value, setValue] = React.useState("")
  const [results, setResults] = React.useState<any>([])
  const [suggestions, setSuggestions] = React.useState<
    Array<{
      label: string
      slug: string
    }>
  >([])

  const handleFetchProducts = () => {
    if (value && !!fetchProducts) {
      const products: any = matchSorter(
        productData.products || [],
        value.trim(),
        {
          keys: searchKeys,
        }
      )

      if (products.length > 0) {
        const uniqSuggestions =
          (_.uniqBy(
            _.flatten(
              products.map(
                ({ categories }) =>
                  categories &&
                  categories.map(({ name, slug }) => ({
                    label: name,
                    slug,
                  }))
              )
            ),
            "slug"
          ).filter((s) => !!s) as Array<{
            label: string
            slug: string
          }>) || []
        const withCollectionPaths = uniqSuggestions.map(({ label, slug }) => {
          const path = collectionPaths.find(
            (paths) => paths.indexOf(slug) !== -1
          )
          return { label, slug: path ? getCollectionUrl(path.join("/")) : "" }
        })

        setSuggestions(withCollectionPaths)

        const placeholderImage = _.get(productData, "placeholderImage[0].url")
        const formattedProducts: any[] = []
        let matchingVariants: any[] = matchSorter(products, value, {
          keys: searchKeys,
        })
        matchingVariants.forEach((product) => {
          if (product.variants) {
            let matchingVariants: any[] = matchSorter(product.variants, value, {
              keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "sku" }],
            })
            matchingVariants.forEach((matchingVariant) => {
              if (
                !matchingVariant.productStatus ||
                (matchingVariant.productStatus &&
                  matchingVariant.productStatus === "Active")
              ) {
                formattedProducts.push({
                  slug: product.slug,
                  image: matchingVariant.mainImage || placeholderImage,
                  name: product.productName,
                  model: matchingVariant.sku,
                  series: product.series,
                  price: matchingVariant.price,
                })
              }
            })
          }
          let matchingProducts: any[] = matchSorter([product], value, {
            keys: [
              {
                threshold: matchSorter.rankings.WORD_STARTS_WITH,
                key: "productName",
              },
              { threshold: matchSorter.rankings.CONTAINS, key: "sku" },
              { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: "tags" },
            ],
          })
          matchingProducts.forEach(() => {
            formattedProducts.push({
              slug: product.slug,
              image: product.mainImage || placeholderImage,
              name: product.productName,
              model: product.sku,
              series: product.series,
              price: product.price,
            })
          })
        })

        setResults(formattedProducts)
      }
    }
  }

  const handleFetchProductsDebounced = _.debounce(handleFetchProducts, 100, {
    maxWait: 250,
    leading: true,
    trailing: true,
  })

  React.useEffect(() => {
    handleFetchProductsDebounced()
  }, [value])

  const onChange = (event) => {
    setValue(_.get(event, "target.value", ""))
  }

  const onKeyDown = (event: any) => {
    if (event.key === ESCAPE_KEY && event.currentTarget) {
      event.currentTarget.blur()
    }

    if (event.key === ENTER_KEY && event.currentTarget) {
      router.push(getSearchByKeywordUrl(value))
      onRequestClose()
    }
  }

  return (
    <Box height="100%" background="white">
      <Box
        mt={bp("30px", null, "24px")}
        mb={bp("50px", null, "62px")}
        p={bp(
          "0 20px 0 20px",
          null,
          "0 calc((2 / 16) * 100vw)",
          "0 calc((4 / 16) * 100vw) 0 calc((4 / 16) * 100vw + 16px)"
        )}
      >
        <SearchInput
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          isLoading={false}
        />
      </Box>
      {results.length === 0 ? (
        <Box
          p={bp(
            "0 20px 0 20px",
            "0 20px 0 20px",
            "0 calc((2 / 16) * 100vw)",
            "0 calc((4 / 16) * 100vw)"
          )}
        >
          <Text
            mb="15px"
            fontFamily="Avenir"
            fontSize="16px"
            fontWeight="800"
            lineHeight="20px"
            color={colors.dark}
          >
            Popular Search Terms
          </Text>
          {popularSearchTerms.map((searchTerm) => (
            <Text
              key={searchTerm}
              mb="13px"
              fontFamily="Avenir"
              fontSize="16px"
              fontWeight="500"
              lineHeight="22px"
              color={colors.midAccessible}
              onClick={() => {
                setValue(searchTerm)
              }}
              _hover={{ cursor: "pointer" }}
            >
              {searchTerm}
            </Text>
          ))}
        </Box>
      ) : (
        <Flex
          flexDirection={bp("column", null, "row")}
          w="100%"
          h="100%"
          p={bp(
            "0",
            null,
            "0 calc((0.5 / 16) * 100%)",
            "0 calc((2 / 16) * 100vw) 0 calc((0.5 / 16) * 100vw)"
          )}
        >
          <Box
            gridRow={1}
            flexShrink={0}
            w={bp("100%", null, "2col")}
            m={bp("0 20px 50px", null, "0")}
            pr={bp("0", null, "calc((0.5 / 16) * 100vw)")}
          >
            <Text
              mb="15px"
              fontFamily="Avenir"
              fontSize="16px"
              fontWeight="800"
              lineHeight="20px"
              color={colors.dark}
            >
              Top Suggestions
            </Text>
            {suggestions.map((suggestion) => (
              <Link key={suggestion.slug} href={suggestion.slug} useAnchor>
                <Text
                  key={suggestion.slug}
                  mb="13px"
                  fontFamily="Avenir"
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="22px"
                  color={colors.midAccessible}
                  _hover={{ cursor: "pointer" }}
                >
                  {suggestion.label}
                </Text>
              </Link>
            ))}
          </Box>
          <Box height="100%">
            <Grid
              w="100%"
              h="100%"
              paddingBottom="16px"
              templateColumns={bp(
                "repeat(2, minmax(0, 1fr))",
                null,
                "repeat(4, minmax(0, 1fr))"
              )}
              templateRows={bp("initial", null, "min-content")}
              columnGap={bp("10px", null, "24px")}
              rowGap={bp("24px", null, "80px")}
              overflowY="auto"
              background="white"
            >
              {results.map((product: CardProps, idx: number) => {
                return (
                  <Box key={idx}>
                    <Card key={idx} {...product} />
                  </Box>
                )
              })}
            </Grid>
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default SearchByKeyword
