export default {
  Footer: {
    terms: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    copyrightText: "© 2021 SKB Corporation, Inc. All Rights Reserved",
    address: {
      name: "Corporate Headquarters",
      line1: "434 West Levers Place",
      city: "Orange",
      state: "CA",
      zip: "92867",
    },
    phone: "Phone: 714.637.1252",
    fax: "Fax: 714.283.0425",
  },
  LocationSearch: {
    header: "Find a Dealer",
    content:
      "Are you finally convinced that you need an SKB Case to protect your valuable investment? Do you want to inspect one with your own hands and eyes? You can locate the closest dealer to you by choosing a search below.",
    search: "SEARCH",
    inputPlaceholder: "Enter city, state, country or zip code",
  },
}
