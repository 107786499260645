import React from "react"
import { throttle } from "lodash"

const useClickHandler = ({
  onClick,
  onCleanUp,
  runForProps,
}: {
  onClick: any
  onCleanUp?: () => void
  runForProps?: Array<any>
}) => {
  const onClickThrottled = throttle(onClick, 100)

  React.useEffect(
    (): any => {
      window.addEventListener("click", onClickThrottled, false)

      return () => {
        window.removeEventListener("click", onClickThrottled, false)
        onCleanUp && onCleanUp()
      }
    },
    runForProps ? runForProps : []
  )
}

export default useClickHandler
