import "nprogress/nprogress.css"
import NProgress from "nprogress"
import React from "react"
import NextLink from "next/link"
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react"
import { LinkProps as NextLinkProps } from "next/dist/client/link"
import { PropsWithChildren } from "react"
import { formatUrl } from "@utils/urls"

NProgress.configure({ showSpinner: false, trickleSpeed: 50 })

export type NextChakraLinkProps = PropsWithChildren<
  NextLinkProps &
    Omit<ChakraLinkProps, "as"> & { external?: boolean; useAnchor?: boolean }
>

const Link = ({
  href = "",
  as,
  replace,
  scroll,
  shallow,
  prefetch,
  external,
  useAnchor,
  children,
  ...chakraProps
}: NextChakraLinkProps) => {
  return useAnchor ? (
    <ChakraLink
      href={formatUrl(href)}
      onClick={() => {
        if (href) {
          NProgress.start()
        }
      }}
      cursor="pointer"
      color="midAccessible"
      _hover={{ color: "dark", textDecoration: "underline" }}
      _active={{ color: "primary", textDecoration: "underline" }}
      _focus={{
        textDecoration: "underline",
      }}
      {...chakraProps}
    >
      {children}
    </ChakraLink>
  ) : (
    <NextLink
      passHref
      href={external ? href : formatUrl(href)}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
    >
      <ChakraLink
        target={external ? "_blank" : undefined}
        cursor="pointer"
        color="midAccessible"
        _hover={{ color: "dark", textDecoration: "underline" }}
        _active={{ color: "primary", textDecoration: "underline" }}
        _focus={{
          textDecoration: "underline",
        }}
        {...chakraProps}
      >
        {children}
      </ChakraLink>
    </NextLink>
  )
}

export default Link
