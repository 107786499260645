import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { PortableText } from "@utils/sanity"
import { Logo } from "../universal/icons"
import { MobileOnly } from "../../utils/MediaQueries"

import Body from "../typography/Body"
import Button from "@components/universal/Button"
import Heading from "../typography/Heading"
import Link from "@components/Link"
import Spacer from "../universal/Spacer"
import strings from "../../constants/strings"
import colors from "../../constants/colors"

import { Socials } from "./index"
import { FooterProps, PortableTextContainer } from "./index"

const MobileFooter: React.FC<FooterProps> = ({ footerData }) => (
  <MobileOnly>
    <Box bg="dark" pt="2xl" pb="xl" as="footer" color="#FFF">
      <Link href="/">
        <Button
          h="initial"
          tertiary
          borderRadius={0}
          p="1.125rem 1.25rem"
          flexShrink={0}
        >
          <Logo height="33.8px" width="84px" />
        </Button>
      </Link>
      <Spacer size="xl" />
      {footerData?.categories.map((item, index) => (
        <Flex
          direction="column"
          key={item.title}
          alignItems="flex-start"
          gridColumn={`${(index + 2) * 2} / ${(index + 2) * 2 + 2}`}
          pb="3rem"
          ml="1.5rem"
        >
          <Heading size="6" pb="lg">
            <Text fontFamily="Avenir" fontSize="19px" fontWeight="800">
              {item.title}
            </Text>
          </Heading>
          {item.subcategories?.map((subItem, index) => (
            <React.Fragment key={subItem.title}>
              {!!index && <Spacer size="s" />}
              <Link href={subItem.slug!} style={{ color: colors.midLight }}>
                <Text fontFamily="Avenir" fontSize="16px">
                  {subItem.title}
                </Text>
              </Link>
            </React.Fragment>
          ))}
        </Flex>
      ))}
      <Flex mb="3rem" mt="-1rem">
        <Socials
          instagramLink={footerData.instagramLink}
          facebookLink={footerData.facebookLink}
          twitterLink={footerData.twitterLink}
          youtubeLink={footerData.youtubeLink}
        />
      </Flex>
      <Flex
        direction="column"
        color={colors.midLight}
        fontFamily="Avenir"
        fontSize="16px"
        ml="1.5rem"
        mb="3rem"
      >
        <PortableTextContainer>
          {footerData?.contact && <PortableText blocks={footerData.contact} />}
        </PortableTextContainer>
      </Flex>
      <Flex mt="lg" ml="lg">
        <Body color="midLight" fontFamily="Avenir" fontSize="13.5px">
          {footerData.copyright}
        </Body>
      </Flex>
      <Flex mt="lg" ml="lg">
        <Link href={footerData.privacyPolicy} mr="m">
          <Body color="midLight" fontSize={"13.5px"} fontFamily="Avenir">
            {strings.Footer.privacyPolicy}
          </Body>
        </Link>
        <Link href={footerData.terms} mr="m">
          <Body color="midLight" fontSize={"13.5px"} fontFamily="Avenir">
            {strings.Footer.terms}
          </Body>
        </Link>
        <Button
          p="0"
          id="mobileTermlyButton"
          className="termly-display-preferences"
          variant="link"
        >
          <Text color="midLight" fontSize={"13.5px"} fontFamily="Avenir">
            Consent Preferences
          </Text>
        </Button>
      </Flex>
    </Box>
  </MobileOnly>
)

export default MobileFooter
