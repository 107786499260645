const CartLink: React.FC<any> = () => {
  return (
    <div className="rsSessionElement rsCartLinkAndItemCountWrapper rsHidden">
      <a className="rsCartLink rsCartIcon" href=""></a>
      <span className="rsCartCount"></span>
    </div>
  )
}

export default CartLink
