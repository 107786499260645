import React from "react"
import Layout from "../index"
import { getClient } from "@utils/sanity"
import { getSearchResults } from "@components/Search/queries"
import _ from "lodash"

const client = getClient()

const withContentfulData: any = (WrappedComponent: any) => (props: any) => {
  const collectionPaths = require("@data/collectionPaths")
  const [layoutData] = require("@data/layoutData")
  const productData = require("@data/productData")
  const searchData = require("@data/searchData")
  const menuProps = React.useMemo(() => {
    if (layoutData?.mainNavigation) {
      const data = layoutData?.mainNavigation

      if (data.length) {
        return data.map((d) => {
          return {
            title: d.title,
            slug: d.slug,
            useFiveColumns: d.useFiveColumns,
            subcategories: d.subcategories.map((s) => {
              return {
                title: s.title,
                slug: s.slug,
                mobileIndex: s.mobileIndex,
                subcategories:
                  s.items?.map((i) => {
                    return {
                      title: i.title,
                      slug: i.slug,
                      mobileIndex: i.mobileIndex,
                    }
                  }) || [],
              }
            }),
          }
        })
      }
    } else {
      return []
    }
  }, [layoutData])

  const searchProps = searchData || {}
  searchProps.collectionPaths = collectionPaths || []
  searchProps.fetchProducts = (query) => {
    return client.fetch(getSearchResults, {
      query,
    })
  }

  const seoData = layoutData?.seo || {}
  seoData.logo = layoutData?.logo

  return (
    <WrappedComponent
      {...props}
      seoData={layoutData?.seo}
      headerData={{ logo: layoutData?.logo, padding: layoutData?.padding }}
      menuData={menuProps}
      productData={productData}
      searchData={searchProps}
      footerData={layoutData?.footer && layoutData.footer[0]}
    />
  )
}

export default withContentfulData(Layout) as any
