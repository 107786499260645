import React from "react"
import styled from "@emotion/styled"
import colors from "@constants/colors"
import Link from "next/link"
import Image from "next/image"
import { Box, BoxProps, Grid, useDisclosure } from "@chakra-ui/react"
import { MagnifyingGlass, Menu } from "@components/universal/icons"
import { bp } from "@utils/MediaQueries"
import {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "@constants/measurements"
import Button from "@components/universal/Button"
import CartLink from "@rs/CartLink"
import Search from "@components/Search"
import { DesktopNavMenu } from "@components/NavMenu/DesktopNavMenu"
import { MobileNavMenu } from "@components/NavMenu/MobileNavMenu"
import { MenuRecord } from "@components/NavMenu/common"

const Below1200 = styled(Box)`
  height: 100%;

  @media only screen and (min-width: 75rem) {
    display: none;
  }
`

const Above1200 = styled(Box)`
  display: none;

  @media only screen and (min-width: 75rem) {
    display: initial;
    width: 100%;
    height: 100%;
  }
`

export interface HeaderProps extends BoxProps {
  headerData: any
  menuData: MenuRecord[]
  productData: any
  searchData: any
}

const buttonProps = {
  tertiary: true,
  borderRadius: 0,
  p: "lg",
  h: "100%",
  w: "2xl",
}

const Header: React.FC<HeaderProps> = ({
  headerData,
  menuData,
  productData,
  searchData,
  ...rest
}) => {
  const { isOpen: mobileIsOpen, onClose: mobileClose, onOpen } = useDisclosure()

  const [showSearch, setShowSearch] = React.useState<boolean>(false)

  const openSearch = () => {
    setShowSearch(true)
  }

  const closeSearch = () => {
    setShowSearch(false)
  }

  return (
    <Box
      as="header"
      zIndex={10}
      position="fixed"
      top={0}
      width="100vw"
      h={bp(MOBILE_HEADER_HEIGHT, null, DESKTOP_HEADER_HEIGHT)}
      bg={colors.white}
      borderBottom="1px solid"
      borderColor="light"
      {...rest}
    >
      <Search
        {...searchData}
        productData={productData}
        isOpen={showSearch}
        onRequestClose={closeSearch}
      />
      <Grid
        display={bp("flex", null, null, "grid")}
        height="75px"
        alignItems="center"
        justifyContent="space-between"
        h="100%"
        gridTemplateColumns={bp("initial", null, null, "repeat(16, 1fr)")}
      >
        <Link href="/" passHref>
          <Box
            cursor="pointer"
            marginLeft="50px"
            gridColumn="1 / 3"
            padding="0"
            maxH="75px"
            borderRadius={0}
            h={"75px"}
            w={`calc( 200px - ${headerData.padding.paddingLeftRight}px )`}
            position="relative"
          >
            <Image
              src={headerData.logo}
              alt="SKB Logo"
              quality={100}
              loading="eager"
              layout="fill"
              objectFit="contain"
              objectPosition="center"
            />
          </Box>
        </Link>
        <Above1200 gridColumn="3 / 15">
          <Box height="100%">
            <DesktopNavMenu
              data={menuData || []}
              bgColor={"#FFF"}
              fontColor={"#000"}
            />
          </Box>
        </Above1200>
        <Box gridColumn="15 / 17" height="100%" position="relative">
          <Grid
            gridTemplateColumns={bp(
              "repeat(3, 75px)",
              null,
              null,
              "repeat(2, 75px)"
            )}
            h="100%"
            position="absolute"
            right="1.25rem"
          >
            <Button
              tertiary
              onClick={(e) => {
                openSearch()
                e.currentTarget.blur()
              }}
              borderRadius={0}
              h="100%"
            >
              <MagnifyingGlass height="27px" />
            </Button>
            <CartLink />
            <Below1200>
              <Button padding="1rem 0" onClick={onOpen} {...buttonProps}>
                <Menu />
              </Button>
            </Below1200>
          </Grid>
        </Box>
      </Grid>
      <MobileNavMenu
        onClose={mobileClose}
        isOpen={mobileIsOpen}
        data={menuData || []}
      />
    </Box>
  )
}

export default Header
