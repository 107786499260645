import React from "react"
import { useRouter } from "next/router"
import colors from "@constants/colors"
import { Box, Flex } from "@chakra-ui/react"
import Image from "next/image"
import SelectInput from "@components/form/inputs/SelectInput"
import StyledPortableText from "./StyledPortableText"
import VerticalSpace from "@components/VerticalSpace"
import { bp } from "@utils/MediaQueries"
import { getSearchByGearUrl } from "@utils/urls"
import _ from "lodash"

export interface SearchByGearProps {
  searchParams: Array<any>
  image?: string
  description: any
  onRequestClose: () => void
}

const SearchByGear: React.FC<SearchByGearProps> = ({
  searchParams,
  image,
  description,
  onRequestClose,
}) => {
  const router = useRouter()
  const [category, setCategory] = React.useState<string | undefined>()
  const [brand, setBrand] = React.useState<string | undefined>()
  const [model, setModel] = React.useState<string | undefined>()

  const gear = React.useMemo(() => {
    return (
      searchParams.map(({ category, brand, model }) => ({
        category,
        brand,
        model,
      })) || []
    )
  }, [searchParams])
  const categories = _.uniq(gear.map(({ category }) => category).sort()).map(
    (category) => ({ label: category, value: category })
  )
  const [brands, setBrands] = React.useState<
    Array<{ label: string; value: string }>
  >([])
  const [models, setModels] = React.useState<
    Array<{ label: string; value: string }>
  >([])

  const onSelectCategory = (e) => {
    const category = e.target.value
    const filteredBrands = _.uniq(
      gear
        .filter((b) => b.category === category)
        .map(({ brand }) => brand)
        .sort()
    ).map((brand) => ({ label: brand, value: brand }))
    setCategory(category)
    setBrand(undefined)
    setBrands(filteredBrands)
    setModel(undefined)
    setModels([])
  }

  const onSelectBrand = (e) => {
    const brand = e.target.value
    const filteredModels = _.uniq(
      gear
        .filter((b) => b.category === category && b.brand === brand)
        .map(({ model }) => model)
        .sort()
    ).map((model) => ({ label: model, value: model }))
    setBrand(brand)
    setModel(undefined)
    setModels(filteredModels)
  }

  const onSelectModel = (e) => {
    const model = e.target.value
    setModel(model)
  }

  const reset = () => {
    setCategory(undefined)
    setBrand(undefined)
    setBrands([])
    setModel(undefined)
    setModels([])
  }

  return (
    <Box pb="90px">
      <Box
        w="100%"
        mt="8px"
        p={bp(
          null,
          null,
          "0 calc((2 / 16) * 100vw)",
          "0 calc((4 / 16) * 100vw)"
        )}
      >
        <Flex
          direction={bp("column", "column", "row", "row")}
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            width={bp("calc(100% - 40px)", "calc(100% - 40px)", null, null)}
            marginTop={bp("20px", "20px", "10px", "10px")}
            flex={bp(null, null, "1", "1")}
            mr={bp(null, null, "10px", "10px")}
          >
            <SelectInput
              name="Category"
              label="Category"
              value={category}
              options={categories}
              onChange={onSelectCategory}
            />
          </Flex>
          <Flex
            width={bp("calc(100% - 40px)", "calc(100% - 40px)", null, null)}
            marginTop={"10px"}
            flex={bp(null, null, "1", "1")}
            mr={bp(null, null, "10px", "10px")}
          >
            <SelectInput
              name="Brand"
              label="Brand"
              value={brand}
              options={brands}
              onChange={onSelectBrand}
              disabled={brands.length === 0}
            />
          </Flex>
          <Flex
            width={bp("calc(100% - 40px)", "calc(100% - 40px)", null, null)}
            marginTop={"10px"}
            flex={bp(null, null, "1", "1")}
            mr={bp(null, null, "10px", "10px")}
          >
            <SelectInput
              name="Model"
              label="Model"
              value={model}
              options={models}
              onChange={onSelectModel}
              disabled={models.length === 0}
            />
          </Flex>
          <Flex
            width={bp(
              "calc(100% - 40px)",
              "calc(100% - 40px)",
              "127px",
              "127px"
            )}
            marginTop={"10px"}
            as="button"
            alignItems="center"
            justifyContent="center"
            h="58px"
            p={bp(null, null, "0 32px", "0 32px")}
            borderRadius="6px"
            bgColor={colors.dark}
            fontFamily="Avenir"
            fontWeight="800"
            fontSize="16px"
            lineHeight="58px"
            textAlign="center"
            textTransform="uppercase"
            color={colors.white}
            _disabled={{
              bgColor: colors.dark50,
              cursor: "not-allowed",
            }}
            disabled={!category || !brand || !model}
            onClick={() => {
              if (category && brand && model) {
                router.push(getSearchByGearUrl({ category, brand, model }))
                onRequestClose()
                reset()
              }
            }}
          >
            Search
          </Flex>
        </Flex>
      </Box>
      <VerticalSpace height={62} />
      <Flex
        p={bp(
          null,
          null,
          "0 calc((2 / 16) * 100vw)",
          "0 calc((4 / 16) * 100vw)"
        )}
      >
        {image && (
          <Box
            position="relative"
            flexShrink={0}
            w={bp(
              "initial",
              null,
              "calc((2.50 / 16) * 100vw)",
              "calc((1.50 / 16) * 100vw)"
            )}
            mr={bp(
              "0",
              null,
              "calc((0.5 / 16) * 100vw)",
              "calc((0.25 / 16) * 100vw)"
            )}
          >
            <Image
              src={image}
              alt={"Search by Gear"}
              layout="fill"
              objectFit="contain"
              objectPosition="top center"
            />
          </Box>
        )}
        <Box textAlign={bp("center", "center", "left", "left")}>
          <StyledPortableText text={description} />
        </Box>
      </Flex>
    </Box>
  )
}

export default SearchByGear
