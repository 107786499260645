import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/react"
import { CustomDomComponent, motion } from "framer-motion"

export const MotionBox = motion(Box) as CustomDomComponent<
  Omit<BoxProps, "transition">
>

export const MotionFlex = motion(Flex) as CustomDomComponent<
  Omit<FlexProps, "transition">
>
