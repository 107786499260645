import Body, { BodyProps } from "../../typography/Body"
import { Checkmark, Chevron } from "../icons"
import { Box, BoxProps } from "@chakra-ui/react"

import Spacer from "../Spacer"
import colors from "../../../constants/colors"

export interface LineItemProps
  extends Omit<BoxProps, "children" | "title" | "value"> {
  checked?: boolean
  chevron?: boolean
  value?: string
  title: string
  titleProps?: BodyProps
  isDisabled?: boolean
}

const ListItem: React.FC<LineItemProps> = ({
  checked,
  chevron,
  value,
  title,
  titleProps,
  isDisabled,
  ...rest
}) => {
  const disabledProps: Omit<BoxProps, "children"> = isDisabled
    ? {
        cursor: "not-allowed",
        color: "disabled",
      }
    : {}
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      backgroundColor="white"
      borderBottom="1px solid"
      borderColor="light"
      p="1rem .25rem"
      w="100%"
      cursor="pointer"
      onClick={isDisabled ? undefined : rest.onClick}
      _hover={{
        backgroundColor: "light",
        borderColor: "transparent",
      }}
      _active={{
        backgroundColor: "lightDarker",
        borderColor: "transparent",
      }}
      {...rest}
      {...disabledProps}
    >
      <Box display="flex">
        {checked && (
          <>
            <Checkmark
              width="1.25rem"
              height="1.25rem"
              strokeColor={isDisabled ? colors.disabled : undefined}
            />
            <Spacer size="xss" horizontal />
          </>
        )}
        <Body {...(titleProps ?? {})}>{title}</Body>
      </Box>
      <Box display="flex">
        {value && (
          <Body color={isDisabled ? "disabled" : "midAccessible"}>{value}</Body>
        )}
        {chevron && (
          <>
            <Spacer size="xss" horizontal />
            <Chevron
              width="1.25rem"
              height="1.25rem"
              strokeColor={isDisabled ? colors.disabled : colors.midAccessible}
            />
          </>
        )}
      </Box>
    </Box>
  )
}
export default ListItem
