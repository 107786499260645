import React from "react"
import { Box, Text, css, useTheme } from "@chakra-ui/react"
import colors from "@constants/colors"
import Image from "next/image"
import Link from "@components/Link"
import Spacer from "@components/universal/Spacer"
import { bp } from "@utils/MediaQueries"
import { getProductUrl } from "@utils/urls"

export interface CardProps {
  slug: string
  image: string
  name: string
  model: string
  series?: string
  price: string
}

const ProductCard: React.FC<CardProps> = ({
  slug,
  image,
  name,
  model,
  series,
  price,
}) => {
  const theme = useTheme()
  const imageContainerStyles = css({
    width: "100%",
    paddingBottom: "100%",
    img: {
      padding: bp(
        "20px !important",
        null,
        "calc((0.25 / 12) * 100vw) !important"
      ),
    },
  })(theme)

  return (
    <Link
      href={getProductUrl(slug) + "/" + model}
      useAnchor
      flexDirection="column"
      display="flex"
      height="100%"
      _hover={{ textDecor: "none", cursor: "pointer" }}
    >
      <Box
        flexGrow={bp("initial", null, 0)}
        flexShrink={bp("initial", null, 0)}
        w="100%"
        h={bp("calc(50vw - 10px)", null, "auto")}
        p={bp("12px", null, "36px")}
        overflow="hidden"
        bg={colors.light}
      >
        <Box position="relative" w="100%" h="auto" overflow="hidden">
          <Box css={imageContainerStyles} />
          {image && (
            <Image
              src={image}
              layout="fill"
              objectFit="contain"
              objectPosition="center"
            />
          )}
        </Box>
      </Box>
      <Box p={bp("20px 10px 0 10px", null, "26px 0 0 10px")}>
        <Text
          fontFamily="Avenir"
          fontSize={bp("13.5px", null, "19px")}
          fontWeight="800"
          color={colors.dark}
        >
          {name}
        </Text>
        <Text
          fontFamily="Avenir"
          fontSize={bp("13.5px", null, "16px")}
          fontWeight="500"
          color={colors.midAccessible}
        >
          {`Model: ${model}`}
        </Text>
        {series && (
          <Text
            fontFamily="Avenir"
            fontSize={bp("13.5px", null, "16px")}
            fontWeight="500"
            color={colors.midAccessible}
          >
            {`Series: ${series}`}
          </Text>
        )}
        <Spacer />
        <Text
          fontFamily="Avenir"
          fontSize={bp("13.5px", null, "16px")}
          fontWeight="800"
          color={colors.dark}
        >
          {`$${price}`}
        </Text>
      </Box>
    </Link>
  )
}

export default ProductCard
