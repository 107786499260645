import { Box, BoxProps } from "@chakra-ui/react"

export type Sizes =
  | "1px"
  | "xss"
  | "xs"
  | "s"
  | "m"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "1.5rem"
export interface SpacerProps extends Omit<BoxProps, "children"> {
  horizontal?: boolean
  size?: Sizes | Sizes[] | string
}

const Spacer: React.FC<SpacerProps> = ({ horizontal, size = "m", ...rest }) => {
  const style: Omit<BoxProps, "children"> = horizontal
    ? { h: "100%", w: size }
    : { w: "100%", h: size }
  return <Box {...style} {...rest} />
}
export default Spacer
