import React from "react"
import { Text, BoxProps } from "@chakra-ui/react"

export type HeadingProps = Omit<BoxProps, "size"> & {
  size?: "1" | "2" | "3" | "4" | "5" | "6" | "md" | "xs"
}

// TODO: Make size responsive
const Heading = ({ size = "1", ...props }: HeadingProps) => {
  let fontSize, lineHeight
  switch (size) {
    case "1":
      fontSize = "heading1"
      lineHeight = "heading1"
      break
    case "2":
      fontSize = "heading2"
      lineHeight = "heading2"
      break
    case "3":
      fontSize = "heading3"
      lineHeight = "heading3"
      break
    case "4":
      fontSize = "heading4"
      lineHeight = "heading4"
      break
    case "5":
      fontSize = "heading5"
      lineHeight = "heading5"
      break
    case "6":
      fontSize = "heading6"
      lineHeight = "heading6"
      break
    case "md":
      fontSize = "md"
      break
    case "xs":
      fontSize = "xs"
      break
  }

  return (
    <Text
      as="h1"
      fontFamily="Avenir"
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight="medium"
      {...props}
    />
  )
}

export default Heading
