import React from "react"
import styled from "@emotion/styled"
import { PortableText } from "@utils/sanity"

const PortableTextContainer = styled.div`
  display: flex;
  width: 100%;

  p,
  h3,
  h6 {
    margin-bottom: 24px;
    font-family: "Avenir";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  h3,
  h6 {
    font-weight: 800;
  }
`

export interface SearchPortableTextProps {
  text: any
}

const SearchPortableText: React.FC<SearchPortableTextProps> = ({ text }) => {
  return (
    <PortableTextContainer>
      {text && <PortableText blocks={text} />}
    </PortableTextContainer>
  )
}

export default SearchPortableText
