import { Flex } from "@chakra-ui/react"

export const IconWrapper = (props: any) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    h="1.5rem"
    w="1.5rem"
    {...props}
  />
)
