import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import colors from "@constants/colors"
import { typographyHelperCss } from "@constants/typography"
import { MagnifyingGlass } from "@components/universal/icons"
import { IconWrapper } from "@components/universal/icons/utils"
import { Spinner } from "@chakra-ui/react"

const Container = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  height: 3.625rem;
  padding-left: 1rem;
  border: solid 1px ${colors.midLight50};
  border-radius: 0.375rem;
  background-color: ${colors.lightest};
  :hover {
    background-color: ${colors.lightDarker};
  }
  :active {
    background-color: ${colors.lightDarkest};
  }
`

const InputWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const placeholderStyles = css`
  opacity: 1;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: normal;
  line-height: 20px !important;
  color: ${colors.midAccessible};
`

const Input = styled.input`
  ${typographyHelperCss("bodyNormal")}
  width: 100%;
  height: 3.625rem;
  padding: 0 1rem;
  border: none;
  background-color: transparent;
  color: ${colors.dark};

  &:focus,
  &:active {
    outline: none;
  }

  ::-webkit-input-placeholder {
    ${placeholderStyles}
  }
  ::-moz-placeholder {
    ${placeholderStyles}
  }
  :-ms-input-placeholder {
    ${placeholderStyles}
  }
  :-moz-placeholder {
    ${placeholderStyles}
  }

  @media only screen and (min-width: 1024px) {
    padding-right: 0;
  }
`

interface SearchInputProps {
  value: string
  placeholder?: string
  onChange?: (event: any) => void
  onKeyDown?: (event: any) => void
  isLoading?: boolean
}

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  placeholder,
  onChange,
  onKeyDown,
  isLoading,
}) => {
  return (
    <Container>
      <IconWrapper>
        <MagnifyingGlass strokeColor={colors.dark} />
      </IconWrapper>
      <InputWrapper>
        <Input
          autoFocus={true}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder || "Search"}
        />
        {isLoading ? <Spinner marginRight="1rem" /> : null}
      </InputWrapper>
    </Container>
  )
}

export default SearchInput
