import { BoxProps, Text } from "@chakra-ui/react"

export type BodyProps = Omit<BoxProps, "size"> & {
  size?: "normal" | "medium" | "md" | "small" | "sm" | "xsmall" | "xs"
}

const Body = ({ size, ...props }: BodyProps) => {
  let fontSize, lineHeight
  switch (size) {
    case "small":
    case "sm":
      fontSize = "bodySmall"
      lineHeight = "bodySmall"
      break
    case "xsmall":
    case "xs":
      fontSize = "bodyXs"
      lineHeight = "bodyXs"
      break
    case "normal":
    case "medium":
    case "md":
    default:
      fontSize = "bodyNormal"
      lineHeight = "bodyNormal"
  }

  return (
    <Text
      fontFamily="helvetica"
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight="normal"
      {...props}
    />
  )
}

export default Body
