import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import colors from "@constants/colors"
import { typographyHelperCss } from "@constants/typography"
import { Chevron } from "@components/universal/icons"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import NativeSelect, { NativeSelectProps } from "@material-ui/core/NativeSelect"

export const Container = styled.div<{ error?: boolean }>`
  ${({ error }) => css`
    width: 100%;
    height: 58px;
    padding: 0 1rem;
    border: 1px solid ${colors.midLight50};
    border-radius: 6px;
    background-color: ${error ? colors.errorLightest : colors.white};

    :hover {
      background-color: ${colors.lightDarker};
    }
    :active {
      background-color: ${colors.lightDarkest};
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiInput-formControl {
      div {
        position: absolute;
        right: 0;
      }
    }

    .MuiNativeSelect-select {
      z-index: 1;
      width: 100%;
      height: 1.25rem;
    }

    .MuiNativeSelect-select:focus {
      background-color: transparent;
    }

    .MuiInputBase-root {
      ${typographyHelperCss("bodyNormal")}
      height: 1.25rem;
      margin-top: 1.5rem;
      color: ${error ? colors.error : colors.dark};
    }

    .MuiInput-underline:before {
      border: none;
    }

    && .MuiInput-underline:hover:before {
      border: none;
    }

    .MuiInput-underline:after {
      border: none;
    }

    .MuiInputLabel-root {
      ${typographyHelperCss("bodyNormal")}
      opacity: 1;
      transform: translate(0, 1.25rem) scale(1);
      color: ${error ? colors.error : colors.midLight};
      pointer-events: none;
    }

    && .MuiInputLabel-shrink {
      ${typographyHelperCss("bodyNormal")}
      transform: translate(0, 6px) scale(0.8);
      color: ${error ? colors.errorLight : colors.midLight};

      &.Mui-focused {
        color: ${error ? colors.errorLight : colors.midLight};
      }
    }

    .MuiFormHelperText-root {
      ${typographyHelperCss("bodyXs")}
      margin-top: 20px;
      color: ${error ? colors.error : colors.midLight};
    }
  `}
`

const Option = styled.option``

const IconWrapper = styled.div`
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: translateY(-8px) rotateZ(90deg);
  height: 21px;
  width: 21px;
`

const IconComponent = () => (
  <IconWrapper>
    <Chevron />
  </IconWrapper>
)

interface SelectInputProps extends NativeSelectProps {
  name: string
  label: string
  options: Array<{
    label: string
    value: string | number
    disabled?: boolean
  }>
  error?: boolean
  helperText?: string
}

const SelectInput: React.FC<SelectInputProps> = ({
  name,
  label,
  value,
  options,
  error,
  helperText,
  onChange,
}) => {
  return (
    <Container error={error}>
      <FormControl>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <NativeSelect
          id={name}
          value={value}
          onChange={onChange}
          inputProps={{ name }}
          IconComponent={IconComponent}
        >
          <Option aria-label="None" value="" />
          {options.map(({ label, value, disabled }, i) => (
            <Option key={i} value={value} disabled={!!disabled}>
              {label}
            </Option>
          ))}
        </NativeSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Container>
  )
}

export default SelectInput
